import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

const StallsPage = function () {
  return (
    <Layout>
      <Seo
        title="Stalls"
        description="  Beauty and comfort in their design, each stall is bright, airy,
        and well bedded to provided luxurious comfort for your equine
        athlete."
      />

      {/* Second Section */}
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 ">
        <div className="mx-auto text-base max-w-prose lg:hidden  ">
          <div>
            <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
              fully insulated
            </h2>
            <h3 className="heading-serif">
              Look inside the stalls at Southern Cross
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-1">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none ">
              <StaticImage
                src="../images/the-stalls.jpg"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Southern Cross Stalls"
                loading="eager"
                className="rounded-lg shadow-lg object-cover object-center"
              />
            </div>
          </div>
          <div className="mt-8 lg:mt-0 ">
            <div className="md:max-w-2xl pl-2 lg:p-0 mx-auto hidden lg:grid">
              <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
                fully insulated
              </h2>
              <h3 className="heading-serif">
                Look inside the stalls at Southern Cross
              </h3>
            </div>
            <div className="mt-5 prose prose-lg prose-indigo text-gray-500 mx-auto md:max-w-prose g:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                Beauty and comfort in their design, each stall is bright, airy,
                and well bedded to provided luxurious comfort for your equine
                athlete.
              </p>
              <p>
                Each stall has the deepest cushion of soft stall flooring, and
                we provide lots of bedding in addition.
              </p>
              <p>
                The barn is fully insulated, keeping your four-legged friends
                nice and warm for the winter and cool in the summer.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StallsPage;
